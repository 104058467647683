import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { data } from "./data";
import BackButton from "../BackButton";
import {
  buttonStyle,
  fontStyle,
  fontWeight,
} from "../../Utils/tpscanStyle";

const ScOnBoarding = () => {
  const navigate = useNavigate();
  const [currentStage, setStage] = useState(0);
  const handleSwipe = (x) => {
    if (x < 0 && currentStage > 0) {
      setStage((prevState) => prevState - 1);
    } else if (x > 0 && currentStage < data.length - 1) {
      setStage((prevState) => prevState + 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe(1),
    onSwipedRight: () => handleSwipe(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleBack = () => {
    if (currentStage > 0) {
      setStage((prevState) => prevState - 1);
    } else {
      window.history.back()
    }
  };

  const openScan = () => {
    if (localStorage.getItem("usertpi")) {
      navigate(`/sc/full-scan`, { replace: true });
    }
  };
  if (JSON.parse(localStorage.getItem("reload")) == true && localStorage.getItem("usertpi")) {
    localStorage.setItem("reload", false)
    window.location.reload()
  }
  return (
    <div {...handlers} className=" h-full">
      <div className="h-3/4 relative bg-orange-100">
        <img
          src={data[currentStage].image}
          alt="slides"
          className={` h-full w-full  object-cover`}
        />
        {data[currentStage].inner && (
          <img
            src={data[currentStage].inner}
            alt="inner"
            className="absolute top-24 h-80 w-80 object-contain"
          />
        )}


        <div className="absolute bottom-0 h-2/5 w-full bg-gradient-to-t from-white"></div>
      </div>
      <div className="flex justify-between px-1 absolute top-0 w-full">
        <BackButton onClick={handleBack} />
        <button
          className="me-4 text-sm font-semibold"
          onClick={() => {
            navigate(`/sc/full-scan`, { replace: true })
          }}
        >
          <span
            style={{ ...fontStyle, ...fontWeight }}
          >
            Skip
          </span>
        </button>
      </div>
      <div className="w-full absolute bottom-0 px-5  custom-outline-xyz-o bg-white">
        <h1 className="text-26 px-2" style={{ ...fontStyle, ...fontWeight }}>
          {data[currentStage].title}
        </h1>
        <p className="text-base px-2 mt-3" style={{ ...fontStyle, ...fontWeight }}>
          {data[currentStage].description}
        </p>
        <div >
          <button
            type="button"
            style={{ ...buttonStyle, ...fontStyle }}
            className="w-full bg-black text-white  h-11 mb-1 mt-5 font-semibold text-base shadow-2xl rounded"
            onClick={() => {
              currentStage === data.length - 1 ? openScan() : handleSwipe(1);
            }}
          >
            {currentStage === data.length - 1
              ? localStorage.getItem("usertpi")
                ? "Scan Now"
                : "Take a Trial"
              : "Next"}
          </button>
        </div>

        <div className="text-center">
          {data.map((point, i) => {
            return (
              <span
                key={i}
                className={` ${currentStage === i ?
                  "text-black" : "text-gray-400"
                  } text-3xl`}
              >
                &#8226;
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ScOnBoarding;  