import { API_HOST } from "../BaseUrl"
import client, { headers } from "./config"
import { encrypt, generateHMAC } from "../../Utils/hmac";

export const GetBucketFile = async (data) => {
    return new Promise(async (resolve, reject) => {
        const hmac = await generateHMAC()
        const updatedHeaders = {
            ...headers,
            "X-Rt": hmac.timestamp,
            "X-Hmac": hmac.token
        }
        const _n = encrypt(data)

        try {
            const response = await client.post(API_HOST + 'review-report/get-file', { _n }, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)
        } catch (error) {
            reject(error)
        }

    })

}

export const getJsonTagDetails = async (imageName) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.get(API_HOST + 'json-tags-data',
                {
                    params: { imageName },
                    headers
                },
                { headers })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)
        } catch (error) {
            reject(error)
        }

    })

}

export const postBookAppointment = async () => {
    const hmac = await generateHMAC()
    const updatedHeaders = {
        ...headers,
        "X-Rt": hmac.timestamp,
        "X-Hmac": hmac.token
    }

    return new Promise(async (resolve, reject) => {
        try {
            const mlResults = JSON.parse(localStorage.getItem("tpscore"))
            const payload = {
                tp_uid: JSON.parse(localStorage.getItem("usertpi")).usertpi,
                gaps: mlResults.gaps_poorly_aligned_teeth,
                overall_score: mlResults.overall_score,
                tartar_score: mlResults.tartar_score,
                gum_recession: mlResults.gum_recession,
                gum_swelling: mlResults.gum_swelling,
                cavity_score: mlResults.cavity_score
            }
            const _n = encrypt(payload)
            const response = await client.post(API_HOST + 'bearn-book-appointment', { _n }, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)
        } catch (error) {
            reject(error)
        }

    })

}