import axios from "axios";


export let headers = {
    "Content-Type": "application/json",
    hosts: window.location.origin,
    dentalOfficeName: "bearn"
}

const client = axios.create({
    headers: {
        "Content-Type": "application/json",
        hosts: window.location.origin,
    },
})



client.interceptors.request.use(
    async (config) => {
        const authToken = localStorage.getItem("authToken");
        let currentDate = new Date();
        const decodedToken = authToken ? JSON.parse(atob(authToken.split('.')[1])) : '';

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



client.interceptors.response.use(
    (response) => { return response },
    (error) => {
        if (error && error?.response?.status === 502) {
            // localStorage.setItem("token expired", JSON.stringify(true))
        }
        return Promise.reject(error)
    }
)


export default client