import { useNavigate, useParams } from "react-router-dom"
import close from "../../assets/Icons/close.png"
import blur from "../../assets/invalidImage/blur.png"
import invalid_image from "../../assets/invalidImage/invalid-image.png"
import light from "../../assets/invalidImage/light.png"
import showDemo from "../../assets/invalidImage/show-demo.png"
import error from "../../assets/svg/failed.svg"
import "../TermsAndConds/index.css"
import { tpscanName } from "../../Utils/AppDetails/appDetails"
import { fontStyle, fontWeight, buttonStyle, companyBucket } from "../../Utils/tpscanStyle"
const ReshootPopup = (props) => {
    const { dentalOfficeName } = useParams();

    const { setReshoot, errorType, setCamera, setDemo } = props
    const navigate = useNavigate()
    const company_style_details = JSON.parse(
        localStorage.getItem("company_style_details")
    );
    const fontWeight = {
        fontWeight: company_style_details
            ? `${company_style_details.onboarding_text_font_weight}`
            : 900,
    };
    const buttonStyle = {
        backgroundColor: company_style_details
            ? `${company_style_details.button_background_color}`
            : "#000000",
    };

    const Failed = () => {
        return (
            <div className="h-full bg bg-transparent w-full flex justify-center items-center ">
                <div className="bg-white w-3/4 flex flex-col items-center p-5 rounded-md">
                    <div className="text-end w-full">
                        <button
                            type="button"
                            onClick={() => { setReshoot(false) }}>
                            <img src={close} className="w-6 h-6" alt="close" />
                        </button>
                    </div>
                    <img src={error} alt="error" className=" w-40 h-40" />
                    <p className="text-center my-5 font-medium" style={{...fontStyle,...fontWeight}}> Sorry!! AI is experiencing some difficulty. Please retry in a moment.</p>
                    <div className=" w-full ">
                        <button
                            type="button"
                            className="bg-black text-white w-full h-11 font-semibold text-base shadow-2xl rounded"
                            onClick={() => { localStorage.getItem("usertpi") ? navigate(`/${JSON.parse(localStorage.getItem("usertpi")).usertpi}`) : navigate(`/`) }}>
                           <span style={{...fontStyle}}>Back to Home Page</span>  </button>
                    </div>
                </div>
            </div>
        )
    }

    const MouthFarAway = () => {
        return (
            <div className="relative w-full bg-white " style={{ height: "90%", maxHeight: "90%" }}>
                <div className="flex justify-center mt-1  px-4">
                    <p className="dragger rounded"></p>
                </div>
                <div className="text-end mt-2  px-4">
                    <button type="button" onClick={() => { setReshoot(false) }}> <img src={close} className="w-7 h-7" alt="close" />  </button>
                </div>
                <div className="text-base py-3 px-4">
                    <p className=" font-semibold my-1 text-3xl text-center" style={{ ...fontStyle, fontSize: "32px" }}>Bring your phone closer to your mouth</p>
                </div>
                <div className="absolute bottom-0">
                    <img src={showDemo} className=" h-auto max-w-full" alt="sample" />
                </div>
                <div className={"absolute bottom-0 w-full p-5"}>
                    <button type="button" className="bg-black text-white w-full h-11 font-semibold text-base shadow-2xl rounded" onClick={() => { setCamera(true) }} style={{ ...buttonStyle, ...fontStyle }}> <span>Retake Scan</span>  </button>
                </div>
            </div>

        )

    }


    const Inavlid = () => {
        return (
            <div className="relative h-3/4 w-full bg-white">
                <div className="flex justify-center mt-1  px-4">
                    <p className="dragger rounded"></p>
                </div>
                <div className="flex justify-between  mt-2  px-4">
                    <p className="text-red-600 text-4xl font-semibold" style={{ ...fontStyle }}>Error!</p>
                    <button type="button" onClick={() => { setReshoot(false) }}> <img src={close} className="w-7 h-7" alt="close" />  </button>
                </div>
                <div className="text-base py-3 px-4">
                    <p className=" font-semibold my-1" style={{ ...fontStyle }}>Improper Photo, this may be due to</p>
                    <p className="flex my-3"> <img src={light} alt="light" className=" w-5 h-5 me-3" /> <span style={{ ...fontWeight, ...fontStyle }}> Poor lighting  </span>  </p>
                    <p className="flex my-3"> <img src={invalid_image} alt="invalid" className="w-5 h-5 me-3" /> <span style={{ ...fontWeight, ...fontStyle }}>  The image doesn’t match the reference image </span> </p>
                </div>
                <div className={"absolute bottom-0 w-full p-5"}>
                    <button type="button" className={"bg-white text-black font-normal text-base w-full h-11 border border-black mb-3 rounded"} onClick={() => { setDemo(true) }}> <span style={{ ...fontWeight, ...fontStyle }}>View Reference</span></button>
                    <button type="button" className={"bg-black text-white w-full h-11 font-semibold text-base shadow-2xl rounded"} onClick={() => { setCamera(true) }} style={{ ...buttonStyle }}> <span style={{ ...fontWeight, ...fontStyle, }} > Retake Scan</span> </button>
                </div>
            </div>
        )
    }

    const Blurness = () => {
        return (
            <div className="relative h-3/4 w-full bg-white">
                <div className="flex justify-center mt-1  px-4">
                    <p className="dragger rounded"></p>
                </div>
                <div className="flex justify-between  mt-2  px-4">
                    <p className="text-red-600 text-4xl font-semibold" style={{ ...fontStyle }}>Error!</p>
                    <button type="button" onClick={() => { setReshoot(false) }}> <img src={close} className="w-7 h-7" alt="close" />  </button>
                </div>
                <div className="text-base py-3 px-4">
                    <p className=" font-semibold my-1" style={{ ...fontStyle }}>Improper Photo, this may be due to</p>
                    <p className="flex my-4"> <img src={blur} alt="blur" className="w-5 h-5 me-3" />  <span style={{ ...fontWeight, ...fontStyle }}> Blur Photo </span> </p>
                </div>
                <div className={"absolute bottom-0 w-full p-5"}>
                    <button type="button" className={"bg-white text-black font-normal text-base w-full h-11 border border-black mb-3 rounded"} onClick={() => { setDemo(true) }}> <span style={{ ...fontWeight, ...fontStyle }}>View Reference</span></button>
                    <button type="button" className={"bg-black text-white w-full h-11 font-semibold text-base shadow-2xl rounded"} onClick={() => { setCamera(true) }} style={{ ...buttonStyle }}> <span style={{ ...fontWeight, ...fontStyle, }} > Retake Scan</span> </button>
                </div>
            </div>
        )
    }

    return (
        <div className="h-full z-20 absolute w-full top-0 bg-black/[0.3] backdrop-blur-sm flex items-end">
            {errorType === "mouth far away" && <MouthFarAway />}
            {errorType === "blur detected" && <Blurness />}
            {errorType === "failed" && <Failed />}
            {errorType === "general" && <Inavlid />}
        </div>
    )
}

export default ReshootPopup