import { API_HOST } from "../BaseUrl"
import client, { headers } from "./config"
import { encrypt, generateHMAC } from "../../Utils/hmac";

export const imageUpload = async ({ formData }) => {
    const hmac = await generateHMAC()
    const updatedHeaders = {
        ...headers,
        'Content-Type': 'multipart/form-data',
        "X-Rt": hmac.timestamp,
        "X-Hmac": hmac.token
    }
    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST + `ai/image/analysis`, formData, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response);
            }
            reject(response.data);
        } catch (error) {
            reject(error)
        }
    })
}

export const submitScan = async (data) => {

    const hmac = await generateHMAC()
    const _n = encrypt(data)
    const updatedHeaders = {
        ...headers,
        "X-Rt": hmac.timestamp,
        "X-Hmac": hmac.token
    }
    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST + `ai/folder/analyze`, { _n }, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}

export const createScan = async (data) => {
    const hmac = await generateHMAC()
    const _n = encrypt(data)
    const updatedHeaders = {
        ...headers,
        "X-Rt": hmac.timestamp,
        "X-Hmac": hmac.token
    }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST + `tp-report/store`, { _n }, { headers: updatedHeaders })
            if (response.status === 200) resolve(response);
            else reject(response);
        }
        catch (error) {
            reject(error)
        }

    });


}

export const getTpStatus = async ({ uid, c }) => {
    const hmac = await generateHMAC()
    const updatedHeaders = {
        ...headers,
        "X-Rt": hmac.timestamp,
        "X-Hmac": hmac.token
    }
    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.get(API_HOST + `tp-uid-status/${uid}`, {
                headers: updatedHeaders,
                params: { c }
            })
            if (response.status === 200) {
                resolve(response);
            }
            reject(response);
        }
        catch (error) {
            reject(error)
        }

    });
};
