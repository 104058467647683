import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import welcomeTeeth from "../../assets/illustrations/welcomeTeeth.png";
import "../../App.css";
import { useDispatch } from "react-redux";
import { getTpStatus } from "../../Services/APIs/scan";
import { getTpScanAppDetails, postLogDetails } from "../../Services/APIs/appDetails";
import { useLocation } from "react-router-dom";
import { UAParser } from 'ua-parser-js';

const TpScanLanding = () => {
  const parser = new UAParser();
  const browser = parser.getBrowser(); // Browser details (name, version)
  const os = parser.getOS(); // OS details (name, version)
  const device = parser.getDevice(); // Device details (type, vendor, model)
  const isMobile = device.type === 'mobile' || device.type === 'tablet';

  const [error, setError] = useState();
  const navigate = useNavigate();
  const [uiLoading, setUiLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const { uid } = useParams();
  const location = useLocation();
  const isSC = location.pathname.includes("/sc");
  localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "bearn" }));
  localStorage.setItem("usertpi", JSON.stringify({ usertpi: uid }));
  localStorage.setItem("is_self_check", isSC);

  const takeScan = () => {
    isSC ? navigate(`/sc/onboarding`) : navigate(`/onboarding`);
  };

  const initialCall = async () => {
    postLogDetails({
      uid,
      payLoad: isSC ? "User tried for front camera" : "User tried for back camera"
    })
    await getTpScanAppDetails({ c: "bearn" })
      .then(async (res) => {
        setUiLoading(false);
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("reload", true);
        localStorage.setItem("company_style_details", JSON.stringify(res.data.data));
        await getTpStatus({ uid, c: "bearn" })
          .then((res) => {
            setLoading(false);
            localStorage.setItem("isScanCompleted", false)
          })
          .catch((err) => {
            err.response.status == 410 ? setError(`The report with ${uid} is already used!`) : setError(err.response.data.message[0].msg)
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
        setUiLoading(false);
        err.status === 410 ? setError("Invalid route") : setError("Something went wrong")
      });

  };

  const company_style_details = JSON.parse(localStorage.getItem("company_style_details"));
  const buttonStyle = {
    backgroundColor: company_style_details
      ? `${company_style_details.button_background_color}`
      : "#000000",
  };
  const fontWeight = {
    fontWeight: company_style_details
      ? `${company_style_details.onboarding_text_font_weight}`
      : "",
  };
  const fontStyle = {
    fontFamily: company_style_details
      ? `${company_style_details.onboarding_text_font_style}`
      : "Raleway",
  };

  useEffect(() => {
    initialCall();
  }, []);
  return (
    <div className="h-full">
      <div className="h-1/2">
        <img
          src={welcomeTeeth}
          alt="welcome teeth"
          className="saturation h-full w-full object-contain"
        />
      </div>
      {uiLoading ? (
        <div className="loader-container">
          <div className="loader dark-loader"></div>
        </div>
      ) : (
        <div className="h-1/2 relative flex flex-col justify-center">
          <h5
            style={{ ...fontWeight, ...fontStyle }}
            className=" text-2xl font-semibold text-center mb-3"
          >
            Instant Dental Health Scan
          </h5>
          <div
            style={{ ...fontStyle }}
            className="text-lg font-medium text-center text-black opacity-60 mb-3"
          >
            Snap a photo for
            <br /> AI-powered insights
          </div>
          <div>
            <div className="h-48  flex flex-col items-center px-4 py-2">
              {error ? (
                <h1 className="text-xl text-red-700  font-medium text-center">
                  {error}
                </h1>
              ) :
                (
                  <button
                    style={buttonStyle}
                    type="button"
                    className=" rounded text-white w-full h-11 font-semibold text-base mb-4 shadow-2xl "
                    onClick={() => {
                      takeScan();
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="loader-container">
                        <div className="loader dark-loader"></div>
                      </div>
                    ) : (
                      <span> Scan </span>
                    )}
                  </button>
                )}
            </div>
          </div>

          <div className="absolute bottom-11 w-full px-4">
            <div
              style={{ ...fontStyle }}
              className=" text-xs font-medium flex justify-evenly text-center text-black opacity-60 "
            >
              Images captured will be shared only with authorized dental
              professionals to ensure your privacy and care.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TpScanLanding;
