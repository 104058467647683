import { API_HOST } from "../BaseUrl";
import client, { headers } from "./config";
import { encrypt, generateHMAC } from "../../Utils/hmac";

export const getTpScanAppDetails = ({ c }) => {

  return new Promise(async (resolve, reject) => {
    const hmac = await generateHMAC()
    const updatedHeaders = {
      ...headers,
      "X-Rt": hmac.timestamp,
      "X-Hmac": hmac.token
    }
    try {
      const response = await client.get(API_HOST + `tp-app/${c}`, { headers: updatedHeaders });
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const postLogDetails = async (data) => {
  const hmac = await generateHMAC()
  const _n = encrypt(data)
  const updatedHeaders = {
    ...headers,
    "X-Rt": hmac.timestamp,
    "X-Hmac": hmac.token
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.post(API_HOST + `ping`, { _n }, { headers: updatedHeaders });
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    } catch (error) {
      reject(error);
    }
  });
};