import { configureStore } from "@reduxjs/toolkit";
import fullScanReducer from "./fullScan";
import reportsReducer from "./reports"
import tpScanReducer from "./tpScan"
import teethHealthReducer from "./teethHealth"

export const store = configureStore({
    reducer: {
        fullScan: fullScanReducer,
        reports: reportsReducer,
        tpScan: tpScanReducer,
        teethHealth: teethHealthReducer,
    }
})

export default store