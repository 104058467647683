import close from "../../assets/illustrations/close.png";
import { fontStyle } from "../../Utils/tpscanStyle";
const HealthPopup = (props) => {
  const { popupType, setPopup, contentType } = props;
  const content = {
    cavity:
      "Dental cavities are small holes in your teeth caused by decay. They form when plaque and bacteria damage the enamel, the outer layer of your teeth.",
    tartar:
      "Plaque is a sticky, colorless film of bacteria that forms on your teeth. If not cleaned off, it can harden into tartar, which is a yellow or brown deposit.",
    gum_recession:
      "Gum recession is when the gums around your teeth pull back, exposing more of the tooth or its root.",
    gum_swelling:
      "Gum swelling is when your gums become puffy, red, and sore. It's often a sign of irritation or infection and can make your gums look larger than normal.",
    gaps_poorly_aligned_teeth:
      "Gaps are spaces between teeth, while crooked teeth are teeth that are not straight or aligned properly",
  };

  return (
    <div className="z-30 fixed h-full w-full top-0 left-0 bg-black/[0.5] flex justify-center items-center">
      <div className=" bg-white w-4/5 p-3">
        <div className="flex justify-between px-3 py-3 border-b-2">
          <p className="text-base font-semibold">{popupType} Health Info</p>
          <button
            className=""
            onClick={() => {
              setPopup(false);
            }}
          >
            <img src={close} className="w-6 h-6" alt="close" />
          </button>
        </div>
        <div className="p-3">{content[contentType]}</div>
      </div>
    </div>
  );
};

export default HealthPopup;
