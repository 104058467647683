import front from "../../assets/demoImages/front.jpg"
import upperJaw from "../../assets/demoImages/upper.jpg"
import lowerJaw from "../../assets/demoImages/lower.jpg"
import left from "../../assets/demoImages/left.jpg"
import right from "../../assets/demoImages/right.jpg"

const steps = [
    {
        title: "Front teeth",
        image: front,
        tips: ["Align the front teeth inside the template", "Keep your teeth slightly open."]
    },
    {
        title: "Upper jaw",
        image: upperJaw,
        tips: ["Open your mouth tilt your head backwards","Fit your teeth within the template."]
    },
    {
        title: "Lower jaw",
        image: lowerJaw,
        tips: ["Open your mouth tilt your head downwards", "Fit your teeth within the template."]
    },
    {
        title: "Left side",
        image: left,
        tips: ["Pull the corner of your mouth as back as possible"],
    },
    {
        title: "Right side",
        image: right,
        tips: ["Pull the corner of your mouth as back as possible"]
    }
]


export default steps
