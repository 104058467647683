import CryptoJS from 'crypto-js';

export const generateHMAC = async () => {
    //HMAC stands for "Hash-based Message Authentication Code". It is a cryptographic method used to verify the integrity and authenticity of a message

    const secretKey = process.env.REACT_APP_SECRET_KEY
    const timestamp = Date.now();
    const userAgent = navigator.userAgent;
    const message = `${timestamp}-${userAgent}`;


    // Convert the message and secret key to bytes
    const encoder = new TextEncoder();
    const keyData = encoder.encode(secretKey);
    const messageData = encoder.encode(message);
    const encryptType = { name: 'HMAC', hash: 'SHA-256' }

    // Import the secret key as a cryptographic key
    const cryptoKey = await window.crypto.subtle.importKey('raw', keyData, encryptType, false, ['sign']);

    // Generate the HMAC
    const hmac = await window.crypto.subtle.sign('HMAC', cryptoKey, messageData);

    // Convert the result into a hex string
    const hmacArray = new Uint8Array(hmac);
    const token = Array.from(hmacArray).map(byte => byte.toString(16).padStart(2, '0')).join('');

    return { token, timestamp }
}

export const encrypt = (data) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY
    const stringified = JSON.stringify(data)
    const encrypted = CryptoJS.AES.encrypt(stringified, secretKey).toString()

    return encrypted
}